$blackColor: #333;
$orangeColor: #e24c11;
$grayColor: #e8e3e3;
$whiteColor: #FFF;
$redCalor: red;
$greenCalor: green;
$font: font-family;
$font-staatliches: 'Staatliches', cursive;
$font-prociono: 'Prociono', serif;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

header, nav, section, main, article, aside, footer {
  display: block;
}

body {
        height: 100%;
        width: 100%;
        font-size: 16px;
    color: #2e343a;
}

#root {
height: 100%;
}

img{
  max-width:100%;
  height:auto;
  }
  
  .site {
    position: relative;
    min-height: 100%;
    }
    .content {
    padding-bottom: 90px;
  }
  
         header.site-header {
              max-width: 1170px;
  height: 100px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
          }

  header.site-header .header-wrapper {
    flex-basis: 50%;
    margin-top: 10px;
  }

header.site-header ul li {
  display: inline-block;     
  vertical-align: top;    
  padding-right: 10px; 
} 
header.site-header ul {       
flex-basis: 50%;  
margin-top: 10px;
} 
  
  footer.site-footer {
   position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #040000;
    color: #FFF;
    text-align: center;
  }

.error {
    color: $redCalor;	
    }
.success {
    color: $greenCalor;		
    }
    
.input-error {
        border: 1px solid $redCalor;
    }
.site-footer span {
margin-top: 25px;
display: block;
}

@mixin container {
    max-width: 970px;
    padding: 0 15px;
    margin: 0 auto;    
}
@mixin middle {
    max-width: 600px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;    
}
@mixin h2-tag {
    font-weight: bold;
    text-align: center;  
    font-size: 30px; 
}
@mixin h3-tag {
    text-align: center;
    font-size: 18px;  
    text-transform: uppercase;   
    font-weight: bold;
}


//loader
.loader, .loader:before, .loader:after {
    border-radius: 50%;
  }
  
  .loader {
    width: 100px;
    height: 100px;
    margin: 50px auto;
    position: relative;
    border-top: 4px solid red;
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: loader;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  }
  .loader:before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border-top: 4px solid #30c4f6;
    animation-name: loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: loader;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }
  .loader:after {
    position: absolute;
    content: '';
    top: 24px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    border-top: 4px solid #333333;
    animation-name: loader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: loader;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  }
  
  @-webkit-keyframes loader {
    from{
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from{
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  
  .load-slider {
    margin-bottom: 1500px;
 }
  
  
//loader



@media screen and (min-width:300px) and (max-width:500px) {
   h2 {
    font-size: 1.4rem;  
    }
}    

