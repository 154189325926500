.modal-wrapper
 {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    display: none;
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 1000;
	opacity: 1;
}
.modal-wrapper.show {
	display: block;
}
.modal-table {
    display: table;
    width: 100%;
    height: 100%;
}

.modal-table__cell {
    display: table-cell;
    padding: 0 1em;
    vertical-align: middle;
    text-align: center;
}
.modal-block
 {
display: inline-block;
padding: 30px;
width: 100%;
max-width: 500px;
height: auto;
background: #ffffff;
-webkit-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.22), 0px 19px 60px rgba(0, 0, 0, 0.3);
-moz-box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.22), 0px 19px 60px rgba(0, 0, 0, 0.3);
box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.22), 0px 19px 60px rgba(0, 0, 0, 0.3);
color: #0C0B0B;
text-align: left;
font-size: 13px;
z-index: 9999;
border-radius: 5px;
position: relative;
padding-bottom: 0;
box-sizing: border-box;
-moz-box-sizing: border-box;
}

.close-modal {
text-align: center;
font-size: 25px;
cursor: pointer;
width: 30px;
height: 30px;
position: absolute;
right: 5px;
top: 5px;
background-color: #f26341;
border-radius: 50%;
color: #FFF;
line-height: 23px;
}

.modal-block h3 {
text-align: center;
color: #296976;
font-size: 30px;
font-family: Open Sans Bold;
line-height: 40px;
margin: 10px 0;
}

.modal-block input {
width: 100%;
margin-bottom: 20px;
height: 40px;
padding: 10px;	
}
.modal-block textarea {
width: 100%;
margin-bottom: 20px;
height: 80px;
padding: 10px;	
}
.modal-block input[type="submit"] {
width: 100%;
max-width: 200px;
margin: 10px auto;
display: block;
padding: 0;
background: #f36341;
color: #FFF;
font-size: 16px;
border: 0;
outline: 0;
}
.modal-block .ajax-loader {
display: none !important;	
}
.header-wrapper {
margin: 10px 20px;
display: flex;
}
.header__popup {
flex-basis: 50%;
}
.header__menu {
flex-basis: 50%;    
}
.menu__ul {
list-style-type: none;
}
.menu__ul li {
display: inline-block;  
vertical-align: top;  
padding-left: 10px;
}
 @media screen and (min-width:300px) and (max-width:550px) {
.modal-block h3 {	 
font-size: 20px;
line-height: 25px;
}
}

