.menu__ul {
    list-style-type: none;
    }
.menu__ul li {
position: relative;
}  
.sub-menu {
display: none;
border: 1px solid black;
position: absolute;
left: 0;
top: 20px;
z-index: 9;
margin-top: 0 !important;
}
.sub-menu.show {
display: block;
}
    